<script>
    export let data = null;
    export let active = false;
</script>

<style>
/* Strip Carousel Frame */
.frame {
    position: relative;
  height: 100%;

  margin-right: 15px;
  
  transition: transform 0.8s;
  
  border-radius: 8px;
  
  background-color: #292a36;
  background-color: -moz-linear-gradient(top,  #292a36 0%, #1b1c25 100%);
  background-color: -webkit-linear-gradient(top,  #292a36 0%,#1b1c25 100%);
  background-color: linear-gradient(to bottom,  #292a36 0%,#1b1c25 100%);

  /*
  -webkit-box-shadow: inset 0px 0px 0px 3px rgba(255,255,255,0.1), 0px 10px 10px -7px rgba(0,0,0,1);
  -moz-box-shadow: inset 0px 0px 0px 3px rgba(255,255,255,0.1), 0px 10px 10px -7px rgba(0,0,0,1);
  box-shadow: inset 0px 0px 0px 3px rgba(255,255,255,0.1), 0px 10px 10px -7px rgba(0,0,0,1);
  */
  -webkit-box-shadow: 0px 10px 10px -7px rgba(0,0,0,1);
  -moz-box-shadow: 0px 10px 10px -7px rgba(0,0,0,1);
  box-shadow: 0px 10px 10px -7px rgba(0,0,0,1);

  width: 280px;
  height: 160px;

  color: white;
  font-size: 24pt;
  background-size: cover;
  margin: 10px;

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  
  text-align: center;

  overflow: hidden;
}

.frame.text {
  justify-content: center;
}

.frame.active {
    -webkit-box-shadow: inset 0px 0px 0px 3px rgba(200,255,200,0.6), 0px 10px 10px -7px rgba(0,0,0,1);
    -moz-box-shadow: inset 0px 0px 0px 3px rgba(200,255,200,0.6), 0px 10px 10px -7px rgba(0,0,0,1);
    box-shadow: inset 0px 0px 0px 3px rgba(200,255,200,0.6), 0px 10px 10px -7px rgba(0,0,0,1);

    transform: scale(1.2);
    z-index: 100;
}

/* Strip Carousel Frame Hover */
.frame:hover {  
  transform: scale(1.04);

  cursor: pointer;

  -webkit-box-shadow: inset 0px 0px 0px 3px rgba(255,255,255,0.6), 0px 10px 10px -7px rgba(0,0,0,1);
  -moz-box-shadow: inset 0px 0px 0px 3px rgba(255,255,255,0.6), 0px 10px 10px -7px rgba(0,0,0,1);
  box-shadow: inset 0px 0px 0px 3px rgba(255,255,255,0.6), 0px 10px 10px -7px rgba(0,0,0,1);
}
.frame.active:hover {
    transform: scale(1.24);
}
.title {
  overflow: hidden;
  color: #292a36;
  background: linear-gradient(transparent, white);
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-size: 16px;
  padding: 10px;
  text-shadow: 0 0 2px white;
}
</style>

{#if data}
  <div class:active class:text={(!data.thumbnail && !data.thumbnailURL)} on:click class="frame" style="{data.thumbnailURL ? `background-image:url(${data.thumbnailURL})`:''}{data.thumbnail ? `background-image:url(${data.thumbnail})` : ''}">
    {#if (!data.thumbnail && !data.thumbnailURL) && data.title}
      {data.title.length > 40 ? data.title.substring(0, 40) + '...' : data.title}
    {:else}
      <div class="title">{data.title}</div>
    {/if}
  </div>
{:else}
  <div class:active on:click class="frame">
    <slot></slot>
  </div>
{/if}