
import { readable } from 'svelte/store';
import  { data as siteData } from './site';

/**
 
{
    "id": number,
    "url": {
        "primary": string,
        "secondary": string,
        "control": string|undefined
    },
    "thumbnail": string|undefined,
    "size": "default" | "fullscreen" | "no-header" | undefined,
    "category": "nlp" | "cv" | "bigdata",
    "description": string,
    "name": string
}

 */

/**
 * @param {string} type one of 'cv', 'nlp', 'bigdata'
 */
export default function(type) {
    return readable([], (set) => {
        let reload = null;
        const unsubscribe = siteData.subscribe((site) => {
            const url = site.pods && site.pods[site.theme] || null;

            if(!url) {
                set([]);
                return;
            }

            const doReload = () => {
                fetch(url)
                .then((result) => {
                    return result.json();
                }).then(data => {
                    let pods = data && data.Items || [];
                    pods = pods.filter(pod => pod.category === type || !pod.category);
                    set(pods);
                }).catch(_e => {
                    set([]);
                });
            };
            reload = setInterval(doReload, 15 * 60 * 1000);
            doReload();
        });
        return () => {
            clearInterval(reload);
            unsubscribe();
        };
    });
};