<script>
    import Detections from './components/Detections.svelte'; 
    import { makePersistentTransport } from '../../store/transport.js';
    import { onMount } from 'svelte';
    import { data as siteData } from "../../store/site";
    import pods from "../../store/pods";
    
    let podState = 'idle';
    let podList = [];
    let url = null;
    let iframeSize = 'default';
    let description = '';

    function updatePod() {
        const pod = podList.find(pod => podState === 'demo-api-' + pod.id);
        url = pod && pod.url && pod.url.secondary || null;
        iframeSize = pod && pod.size || 'default';
        description = pod && pod.description || podState;
    }

    let data = makePersistentTransport('pod:cv:ctrl', true);
    onMount(() => {
        const unbindState = data.subscribe((_state) => {
            podState = _state;
            updatePod();
        });
        const unbindPods = pods('cv').subscribe((_pods) => {
            podList = _pods;
            updatePod();
        });
        return () => {
            unbindState();
            unbindPods();
        }
    });
</script>

<style>
    .video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .wrapper {
        width: 100%;
        height: 100%;
        background-size: cover;
        padding-top: 275px;
    }
    .wrapper.cmb {
        background-image: url('/assets/background_secondary_cmb.png');
    }
    .wrapper.aws {
        background-image: url('/assets/background_secondary_aws.png');
    }
    .wrapper.bcg {
        background-image: url('/assets/background_secondary_bcg.png');
    }
    section {
    position: absolute;
    left: 60px;
    top: 40px;
    width: 950px;
    }

    section img {
        position: absolute;
        left: 4px;
        top: 27px;
    }

    section h1 {
    position: absolute;
    font-family: "Suisse Int'l";
    font-weight: bold;
    font-size: 62px;
    top: 135px;
    left: 4px;
    margin-top: 0px;
    margin-bottom: 0px;
    }

    section hr {
    position: absolute;
    background-color:rgb(0, 0, 0);
    color:rgb(0, 0, 0);
    border:rgb(0, 0, 0);
    height:4px;
    width: 960px;
    top: 229px;
    margin-top: 0px;
    margin-bottom: 0px;
    }
    section h2 {
  position: absolute;
  font-family: "Suisse Int'l";
  font-weight: bold;
  font-size: 31px;
  top: 247px;
  left: 4px;
  margin-top: 0px;
  margin-bottom: 0px;
}
    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        border:none;
    }
    iframe.default {
        left: 60px;
        width: 960px;
        top: 360px;
        height: 1500px;
    }
    iframe.no-header {
        left: 60px;
        width: 960px;
        top: 60px;
        height: 1800px;
    }
    iframe.fullscreen {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
</style>

{#if podState === 'idle'}
    <video src="/assets/pods/cv/{$siteData.theme}_idle_poster.mp4" autoPlay loop muted class="video" crossorigin="anonymous" />
{:else}
    <div class="wrapper {$siteData.theme}">
        {#if podState === 'demo-pose'}
            <section>
                <img src="/assets/{$siteData.theme}_logo.svg">
                <h1 class="title">Computer Vision</h1>
                <hr />
                <h2 class="title">Pose Detection</h2>
            </section>
            <Detections></Detections>
        {:else if url}
            {#if iframeSize === 'default'}
                <section>
                    <img src="/assets/{$siteData.theme}_logo.svg">
                    <h1 class="title">Computer Vision</h1>
                    <hr />
                    <h2 class="title">{description}</h2>
                </section>
            {/if}
            <iframe scrolling="no" class={iframeSize} src={url} title="Demo Content" sandbox="allow-forms allow-same-origin allow-orientation-lock allow-pointer-lock allow-presentation allow-scripts" allow="autoplay *; geolocation *; gyroscope *; accelerometer *; microphone *; camera *; screen-wake-lock *"></iframe>
        {/if}
    </div>
{/if}
